import { createStore } from 'vuex'

export default createStore({
  state: {
    produseCategorizate:{},
    produseSubcategorizate: {}
  },
  mutations: {
    updateProduseSubcategorizate(state, payLoad){
      state.produseSubcategorizate = payLoad;
    }
  },
  actions: {
  },
  modules: {
  }
})
