<template>
  <div class="pr-main-container">
    <div class="pr-second-container">
      <div class="pr-logo-container">
        <img
          src="../../assets/images/LOGO-Isoletta.png"
          height="80"
          width="260"
        />
      </div>
      <div class="pr-form">
        <form class="pr-theForm">
          <div class="pr-form-group" style="margin-bottom: 10px">
            <select
              class="form-control"
              v-model="tipSelectat"
              @change="selectareTip($event)"
            >
              <option value="" selected disabled hidden>
                Alege categoria produsului
              </option>
              <option v-for="tip in tipuri" :value="tip.id" :key="tip.id">
                {{ tip.id }}
              </option>
            </select>
          </div>
          <div class="pr-form-group" style="margin-bottom: 10px">
            <select
              class="form-control categorie-dropdown"
              v-model="categorieSelectata"
              
            >
              <option value="" selected disabled hidden>
                Alege subcategoria produsului
              </option>
              <option
                class="selectOption"
                v-for="categorie in categorii"
                :value="categorie"
                :key="categorie"
              >
                <span v-html="categorie"></span>
              </option>
            </select>
          </div>

          <div class="pr-form-group" style="margin-bottom: 10px">
            <textarea
              v-model="titlu"
              type="textarea"
              class="pr-form-control"
              placeholder="Titlu"
            >
            </textarea>
          </div>
          <div class="pr-form-group" style="margin-bottom: 10px">
            <input
              v-model="pret"
              type="number"
              class="pr-form-control"
              placeholder="Pret"
            />
          </div>
          <div class="pr-form-group" style="margin-bottom: 10px">
            <textarea
              rows="4"
              v-model="descriere"
              type="textarea"
              class="pr-form-control"
              placeholder="Descriere"
            >
            </textarea>
          </div>
          <div class="add-product-button">
            <button
              @click.prevent="addProduct()"
              class="btn btn-primary"
              style="width: 280px; margin-top: 20px"
            >
              Adauga Produs
            </button>
          </div>
          <div class="closeBtnContainer">
            <button @click.prevent="backToProducts()" class="closeWindowBtn">
              Go Back
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFirestore,
  getDoc,
  collection,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import router from "../../router/index.js";
export default {
  name: "AddProduct",
  data() {
    return {
      tipSelectat: "",
      categorieSelectata: "",
      tipuri: [],
      categorii: [],
      menu: []
    };
  },
  methods: {
    async addProduct() {
      const stripHTML = (string) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = string;
        return tmp.textContent || tmp.innerText || "";
      };

      const db = getFirestore();
      const docRef = doc(db, "data", "isoletta");
      const generatedID = doc(collection(db, "data"));

      let indexUltimulProdus = 0, indexSubcat = null;
      for(let produs of this.menu){
        if(produs.subcategorieObj.subcategorie === this.categorieSelectata.trim().replace('  ', ' ')){
          indexSubcat = produs.subcategorieObj.index;
          if(indexUltimulProdus < produs.index){
            indexUltimulProdus = produs.index;
          }
        }
      }
      let descriereProdus = (typeof this.descriere) == "undefined" ? "" : this.descriere
      const newDoc = {
        titlu: this.titlu,
        subcategorie: stripHTML(this.categorieSelectata.trim()),
        index: indexUltimulProdus + 1,
        subcategorieObj: {
          index: indexSubcat,
          subcategorie: this.categorieSelectata.trim().replace('  ', ' ')
        },
        pret:this.pret,
        descriere: descriereProdus,
        categorie: stripHTML(this.tipSelectat.trim().toLowerCase()),
        id: generatedID.id,
      };
      console.log(generatedID);

      await updateDoc(docRef, {
        menu: arrayUnion(newDoc),
      });

      console.log("newDoc", newDoc);

      router.push({ path: "/products" });
    },

    selectareTip(event) {
      this.categorii = this.tipuri.find(
        (x) => x.id === event.target.value
      ).subcategorie;
    },

    backToProducts() {
      router.push({ path: "/products" });
    },
  },
  async mounted() {
    let db = getFirestore();
    const restaurant = await getDoc(doc(db, "data", "isoletta"));
    const documents = restaurant.data().menu;
    this.menu = documents;

    let categorii = [];

    for (let document of documents) {
      if (!categorii.some((e) => e.id === document.categorie)) {
        categorii.push({ id: document.categorie, subcategorie: [] });
      }

      let exist = false;
     
      for (let i = 0; i < categorii.length; i++) {
        if (categorii[i].id === document.categorie) {
          for (let subcategorie of categorii[i].subcategorie) {
            if (subcategorie === document.subcategorie) {
              exist = true;
            }
          }
          if (!exist) {
            categorii[i].subcategorie.push(document.subcategorie);
          }
        }
      }
      this.tipuri = categorii;
    }
  },
};
</script>


<style>
.closeBtnContainer {
  width: 300px;
  padding-left: 37%;
  padding-top: 10px;
}

.closeWindowBtn {
  border-radius: 30px;
  font-size: 14px;
  padding: 6px 14px;
  background: #0d6efd;
  border: 0;
  transition: all 0.3s linear;
  color: white;
  line-height: 1;
  font-weight: 600;
  align-items: center;
}

.selectOption {
  max-width: inherit;
}

.pr-main-container {
  background: #116ba6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #116ba6,
    #d2a45e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #116ba6,
    #d2a45e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100vh;
}

.pr-second-container {
  margin: auto;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 10px;
  position: absolute;
  top: 9%;
  left: 40vw;
  right: 40vw;
  padding-top: 15px;
  padding-bottom: 15px;
}

.pr-logo-container {
  display: flex;
  justify-content: center;
}

.add-product-text {
  text-align: center;
  font-family: "Helvetica";
  font-size: 0.9em;
  font-weight: 200;
  color: #222;
  padding-top: 10px;
}

.pr-form-group {
  width: 300px;
  padding: 10px;
}

.pr-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #e9ecef;
  background-clip: padding-box;
  border: 1px solid #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-colo 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.add-product-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pr-form {
  display: flex;
  justify-content: center;
}

.pr-theForm {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1500px) {
  .pr-second-container {
    margin: auto;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    left: 37vw;
    right: 37vw;
  }
}

@media screen and (max-width: 1200px) {
  .pr-second-container {
    margin: auto;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    left: 34vw;
    right: 34vw;
  }
}

@media screen and (max-width: 900px) {
  .pr-second-container {
    margin: auto;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    left: 30vw;
    right: 30vw;
  }
}

@media screen and (max-width: 750px) {
  .pr-second-container {
    margin: auto;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    left: 25vw;
    right: 25vw;
  }
}

@media screen and (max-width: 600px) {
  .pr-second-container {
    margin: auto;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    left: 20vw;
    right: 20vw;
  }
}

@media screen and (max-width: 500px), screen and (max-height: 600) {
  .pr-second-container {
    margin: auto;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    top: 5%;
    left: 15vw;
    right: 15vw;
  }
}

@media screen and (max-width: 410px), screen and (max-height: 830) {
  .pr-second-container {
    margin: auto;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    top: 3%;
    left: 10vw;
    right: 10vw;
  }
  .pr-main-container {
    background: #116ba6;
    background: linear-gradient(to top, #116ba6, #d2a45e);
    height: 110vh;
  }
}

@media screen and (max-width: 360px), screen and (max-height: 700) {
  .pr-second-container {
    margin: auto;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    top: 1%;
    left: 2vw;
    right: 2vw;
  }
  .pr-main-container {
    background: #116ba6;
    background: linear-gradient(to top, #116ba6, #d2a45e);
    height: 114vh;
  }
}

@media screen and (max-width: 330px), screen and (max-height: 570) {
  .pr-second-container {
    margin: auto;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    top: 0%;
    left: 2vw;
    right: 2vw;
  }
  .pr-main-container {
    background: #116ba6;
    background: linear-gradient(to top, #116ba6, #d2a45e);
    height: 114vh;
  }
}
</style>
