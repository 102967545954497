<template>
  <div v-if="!showProducts" class="prima-pagina" >
    <div class = "firstContainer">
      <div class="firstImageContainer">
        <img class="firstImage" src="..\assets\images\logo.png" />
      </div>
      <div v-for="(subcategorii, categorie) in subcategoriiCategorizate2" :key="categorie" class = "firstBorder"> 
        <h2 class="firstCategory">{{ categorie }}</h2>
        <div v-for="(subcategorie, index) in subcategorii" :key="index">
          <h2 @click="scrollToProduct(subcategorie, categorie)" class="firstSubcategory">{{ subcategorie }}</h2>
        </div>
      </div>
    </div>
    <div class = "firstFooter">
      <p class="firstAddressContainer">Sos. Nordului 7-9 (near the BTT tennis courts)</p>
      <div class = "firstContactContainer">
        <a style="color:#d2a45e; text-decoration: none; " href="tel:+407308220852">+4(0730) 82 20 85,   </a>
        <a style="color:#d2a45e; text-decoration: none; " href="tel:+40212322188"> +4(021) 232 21 88</a>
      </div>
      <div class = "firstSocialContainer">
        <div class = "firstSocialIconsContainer">
          <a href="https://www.facebook.com/isoletta.ristorante"><img class = "facebookImage" src="..\assets\images\facebook.svg" /></a>
        </div>
        <div style="min-width:20px;"></div>
        <div class = "firstSocialIconsContainer">
          <a href="https://www.instagram.com/isolettaristorante/"><img class = "instaImage" src="..\assets\images\instagram.svg" /></a>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="big-container">
    <div class="meniu">
      <ul class="menu_list">
        <div class="foodBarContainer">
          <li
            id = "foodLi"
            class="tab-item_food tab_border tab_border_active"
            @click="removeBorder"
            v-on:click="displayCategory('food')"
          >FOOD</li>
          <li
            class="tab-item_bar tab_border"
            @click="removeBorder"
            v-on:click="displayCategory('bar')"
          >BAR</li>
        </div>
        <div @click="showProducts = false" class="imageContainer">
          <img class="image" src="..\assets\images\logo.png" />
        </div>
        <div class = "wineContainer">
          <li
            class="tab-item_wine tab_border"
            @click="removeBorder"
            v-on:click="displayCategory('wine')"
            style="display:inline-block; "
          >VINI & CHAMPAGNE</li>
        </div>
      </ul>
    </div>

    <div class="main-div">
      <div v-for="(values, key) in produseSubcategorizate" :key="key" :id="key.replace('  ', ' ')" style="display: inline-flex;" class="subcat-master-container" :data-index="this.categorii2[this.categorieSelectata].find(e => e.subcategorie == key.replace('  ', ' ')).index">
        <span class="before-categorie">.</span>
        <div class="dish-container">
          <h2 class="categorie">{{ key }}</h2>
          <div class="containerul">
            <div v-for="produs of values" :key="produs.id" class="produs-master" :data-id="produs.id" :data-index="produs.index">
              <div v-if="produs.categorie === 'bar'" class="produsBar">
                <p class = "p">{{ produs.titlu }}</p>     
                <p class = "p">{{ produs.pret }} lei</p>
              </div>
              <div v-else>
                <div class="produs">
                  <div class="produs-main">
                    <p class = "p">{{ produs.titlu }}</p>
                    <p
                      v-if="produs.descriere.trim() !== ''"
                      class="produs-descriere"
                    >{{ produs.descriere }}</p>
                    <p class = "p">{{ produs.pret }} lei</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div data-index="123456789" class="maseratiContainer subcat-master-container">
        <a href="https://www.autoitalia.ro/oferta-levante-hybrid" target="_blank" >
          <img class="maseratiImage" src="..\assets\images\maserati.jpg" />
        </a>
      </div>
    </div>
    <div class="goUpBtnContainer">
      <img @click="goUpFunction()" class="arrow-up" src="..\assets\images\chevron-up.svg" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import { getFirestore, getDoc, doc } from "firebase/firestore";

export default {
  name: "Home",
  components: {
    Footer: Footer,
  },
  data: () => {
    return {
      showProducts: false,
      categorii: [],
      categorieSelectata: "food",
      produseCategorizate: {},
      vinuriSubcategorizate: {},
      bauturiSubcategorizate: {},
      produseSubcategorizate: {},
      menu: [],
      categorii2: {
        food: [],
        bar: [],
        wine: [],
      },
      subcategoriiCategorizate: {},
      subcategoriiCategorizate2: {
        'FOOD': [],
        'BAR': [],
        'VINI & CHAMPAGNE': []
      }
    };
  },

  methods: {
    scrollToProduct(subcat, cat){
      if(cat == 'VINI & CHAMPAGNE')
        this.categorieSelectata = 'wine';
      else
        this.categorieSelectata = cat.toLowerCase();

      this.subcategorizeazaProduse(
        this.produseCategorizate[this.categorieSelectata]
      );
      
      this.showProducts = true;

      setTimeout(() => {
        // Scoate underline de la meniul de sus
          const food = document.querySelector(".tab-item_food");
          const wine = document.querySelector(".tab-item_wine");
          const bar = document.querySelector(".tab-item_bar");

          bar.classList.remove("tab_border_active");
          food.classList.remove("tab_border_active");
          wine.classList.remove("tab_border_active");

          if (cat.toLowerCase() == 'bar') {
            bar.classList.add("tab_border_active");
          } else if (cat.toLowerCase() == 'food') {
            food.classList.add("tab_border_active");
          } else {
            wine.classList.add("tab_border_active");
          }
        // End underline meniu

        document.getElementById(subcat).scrollIntoView({ behavior: 'smooth' });
      }, 500);
    },

    goUpFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },

    displayCategory(categorie) {
      this.categorieSelectata = categorie;
      this.subcategorizeazaProduse(
        this.produseCategorizate[this.categorieSelectata]
      );
    },
    removeBorder(event) {
      const food = document.querySelector(".tab-item_food");
      const wine = document.querySelector(".tab-item_wine");
      const bar = document.querySelector(".tab-item_bar");

      bar.classList.remove("tab_border_active");
      food.classList.remove("tab_border_active");
      wine.classList.remove("tab_border_active");

      if (event.target.innerText.includes("BAR")) {
        bar.classList.add("tab_border_active");
      } else if (event.target.innerText.includes("FOOD")) {
        food.classList.add("tab_border_active");
      } else {
        wine.classList.add("tab_border_active");
      }
    },

    showDivs(){
      console.log(document.querySelectorAll('.produs-master'));
    },

    subcategorizeazaProduse(listaProduse) {
      this.produseSubcategorizate = {};
      for (let produs of listaProduse) {
        const subcategorie = produs.subcategorie;

        if (this.produseSubcategorizate[subcategorie] == null) {
          this.produseSubcategorizate[subcategorie] = [produs];
        } else {
          this.produseSubcategorizate[subcategorie] = [
            ...this.produseSubcategorizate[subcategorie],
            produs,
          ];
        }
      }
    },
  },
  async mounted() {
    const db = getFirestore();
    const restaurant = await getDoc(doc(db, "data", "isoletta"));
    const documents = restaurant.data().menu;
    this.menu = documents;

    for (let document of documents) {
      if (!this.categorii.includes(document.categorie)) {
        this.categorii.push(document.categorie);
      }
      if (this.produseCategorizate[document.categorie] == null) {
        this.produseCategorizate[document.categorie] = [document];
      } else {
        this.produseCategorizate[document.categorie] = [
          ...this.produseCategorizate[document.categorie],
          document,
        ];
      }
    }

    this.subcategorizeazaProduse(
      this.produseCategorizate[this.categorieSelectata]
    );
    
    let tempArr = [];
    for(let produs of this.menu){
      if(!tempArr.find(element => element.subcategorie == produs.subcategorieObj.subcategorie)){
        let tempObj = produs.subcategorieObj;
        tempObj['categorie'] = produs.categorie;
        tempArr.push(tempObj);
      }
    }
    tempArr.sort((a,b) => a.index - b.index);

    let foodArr = [], barArr = [], wineArr = [];
    tempArr.forEach(subcatObj => {
      if(subcatObj.categorie == 'food'){
        foodArr.push(subcatObj.subcategorie);
      }
      else if(subcatObj.categorie == 'bar'){
        barArr.push(subcatObj.subcategorie);
      }
      else{
        wineArr.push(subcatObj.subcategorie);
      }
    })
    this.subcategoriiCategorizate2['FOOD'] = foodArr;
    this.subcategoriiCategorizate2['BAR'] = barArr;
    this.subcategoriiCategorizate2['VINI & CHAMPAGNE'] = wineArr;
  },
  beforeUpdate(){
    let tempArr = [];
    
    for(let produs of this.menu){
      if(produs.categorie == this.categorieSelectata){
        if(!tempArr.find(element => element.subcategorie == produs.subcategorieObj.subcategorie)){
          tempArr.push(produs.subcategorieObj);
        }
      }
    }
    this.categorii2[this.categorieSelectata] = tempArr.sort((a,b) => a.index - b.index);
  },
  updated(){
    let containers = document.querySelectorAll('.subcat-master-container');
    let arr = Array.from(containers);
    let sorted = arr.sort((a, b) => a.getAttribute('data-index') - b.getAttribute('data-index'));
    sorted.forEach(e => {
      document.querySelector('.main-div').appendChild(e);
    })

    document.querySelectorAll('.containerul').forEach(parent => {
      let produse = parent.querySelectorAll('.produs-master');
      let arr = Array.from(produse);

      let sortedProduse = arr.sort((a, b) => a.getAttribute('data-index') - b.getAttribute('data-index'));
      sortedProduse.forEach(e => {
        parent.appendChild(e);
      })
    })
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&family=Roboto:wght@100&display=swap");
.firstSocialContainer{
  display:flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.firstContactContainer{
  display:flex;
  justify-content: center;
}

.firstAddressContainer{
  margin-bottom: 0px;
  padding-top:20px;
  color:#d2a45e;
  display:flex;
  justify-content: center;
}

.firstFooter{
  display:flex;
  justify-content: center;
  flex-direction: column;
}

.firstSocialIconsContainer{
  background: #d2a45e;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.facebookImage{
  width: 30px;
  height: 22px;
}

.firstBorder{
  padding-bottom: 24px;
  border-bottom: 4px solid #f1e9d5;
  border-bottom-style: dotted;
}

.firstImageContainer{
  display: flex;
  justify-content: center;
}

.firstImage{
  display: flex;
  justify-content: center;
}

.firstContainer{
  margin-right: 5%;
  margin-left: 5%;
}

.firstCategory{
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-family: "Roboto Condensed";
  padding-top: 40px;
  align-items: start;
  color: #d2a45e;
  font-size: 26px;
}

.firstSubcategory{
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-family: "Roboto Condensed";
  padding-top: 40px;
  align-items: start;
  color: #116ba6;
  font-size: 22px;
  text-align: center;
}

nav { display: flex; justify-content: space-between; }
nav > * { flex: 1; display: flex; align-items: center; justify-content: space-around; }

.navi{
 padding-left:20px;
 padding-right:20px;
}

ul, li {
  list-style-type: none;
}

.navi-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
}

.navi-list li:not(.centered-logo) {
  flex: 1 0 auto;
  text-align: center;
}

.centered-logo img{
  width: 68px;
  height: 100%;
}

.p{
  margin-bottom:0.2rem !important;
}

.wineContainer{
    display:flex; 
    justify-content:center;
    width:30%;
  }

.foodBarContainer{
  display:flex; 
  justify-content:space-between;
  width:30%;
}

.maseratiContainer {
  margin: 10px;
  display: flex;
  justify-content: center;
  padding-left:10%;
  padding-right: 10%;
}

.maseratiContainer img {
  width: 100%;
}

.hiddenElement {
  min-width: 7%;
}

.hiddenElement1 {
  min-width: 79%;
}

.produsBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f1e9d5;
  border-bottom-style: dotted;
  color: #116ba6;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  padding-top: 10px;
}
.arrow-up {
  max-width: 24px;
}

.goUpBtnContainer {
  background: white;
  max-width: 75px;
  height: 72px;
  border-radius: 50%;
  margin-bottom: -28px;
  overflow-x: overlay;
  margin-left: 60%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.before-categorie {
  max-width: 20px;
  max-height: 30px;
  background-color: #d2a45e;
  margin-top: 43px;
  color: #d2a45e;
}

.imageContainer {
  background: #fbf8f1;
  width: 168px;
  height: 168px;
  border-radius: 50%;
  margin-top: 30px;
}

.big-container {
  font-family: "Roboto Condensed";
}
.meniu {
  width: 100%;
  background-color: #fbf8f1;
  margin-bottom: 2%;
  font-family: "Roboto Condensed";
}

.menu_list {
  color: #116ba6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 140px;
  margin-top: 0px;
  padding-left: 10%;
  padding-right: 10%;
}

.menu_list li {
  text-align: center;
  padding: 52px 0px;
  font-size: 20px;
  list-style-type: none;
  font-weight: lighter;
  cursor: pointer;
}

.mystyle {
  width: 100%;
  padding: 25px;
  background-color: coral;
  color: #116ba6;
  font-size: 25px;
  box-sizing: border-box;
}
.main-div {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;
}

.dish-container {
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
}

.categorie {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-family: "Roboto Condensed";
  padding-top: 40px;
  flex-direction: column;
  align-items: start;
  color: #d2a45e;
  font-size: 22px;
}

.produs-main {
  display: flex;
  justify-content: space-between;
  display: inline-block;
  vertical-align: top;
  padding-top: 7px;
}

.produs-main p {
  font-weight: 700;
  font-family: "Roboto Condensed";
  color: #116ba6;
  font-size: 22px;
}

.produs-descriere {
  margin-bottom:0.2rem !important;
  padding-left: 0px;
  font-weight: 200 !important;
  font-family: "Roboto Condensed";
  color: #116ba6;
}

.produs {
  padding-top: 7px;
  border-bottom: 1px solid #f1e9d5;
  border-bottom-style: dotted;
  padding-bottom: 10px;
  color: #116ba6;
  border-bottom-width: medium;
}

@media screen and (max-width: 500px) {
  .firstBorder{
    padding-bottom: 14px;
    border-bottom: 2px solid #f1e9d5;
    border-bottom-style: dotted;
  }

  .firstImage{
    width:100px;  
    height:100px;
  }

  .firstCategory{
    padding-top:20px;
  }

  .firstSubcategory{
    padding-top:15px;
    font-size:16px;
  }

  .meniu {
  padding-right: 25px;
  padding-left: 25px;
  width: 100%;
  background-color: #fbf8f1;
  margin-bottom: 2%;
  font-family: "Roboto Condensed";
}

  #foodLi{
    margin-right:30px;
  }

  .wineContainer{
    display:flex; 
    justify-content:right;
    width:150px;
  }

  .foodBarContainer{
    display:flex; 
    justify-content:left;
    width:150px;
  }

  .maseratiContainer {
    padding: 10px;
    display: flex;
    justify-content: center;
  }

  .maseratiImage {
    width: 100%;
    height: 100%;
  }
  .hiddenElement {
    min-width: 0px;
  }

  .hiddenElement1 {
    min-width: 27%;
  }

  .tab_border {
    border-bottom: 3px solid #fbf8f1;
  }

  .tab_border_active {
    border-bottom: 3px solid #116ba6;
    padding-top: 20px;
  }

  .bigImageContainer {
    display: flex;
    justify-content: center;
  }

  .imageContainer2 {
    background: #fbf8f1;
    width: 64px;
    height: 63px;
    border-radius: 50%;
    margin-bottom: -15px;
  }

  .image2 {
    width: 60px;
    height: 60px;
  }

  .before-categorie {
    max-width: 10px;
    max-height: 20px;
    background-color: #d2a45e;
    margin-top: 43px;
    color: #d2a45e;
  }

  .goUpBtnContainer {
    background: white;
    max-width: 75px;
    height: 61px;
    border-radius: 50%;
    margin-bottom: -25px;
    overflow-x: overlay;
    margin-left: 70%;
    display: flex;
    justify-content: center;
  }

  .image {
    width: 58px;
    height: 58px;
  }

  .imageContainer {
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
  }

  .main-div {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
  }

  .dish-container {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .produs {
    border-bottom: 2px solid #f1e9d5;
    border-bottom-style: dotted;
    color: #116ba6;
  }

  .produs-descriere {
    padding-top: 0px !important;
    padding-left: 0px;
    font-weight: 200 !important;
    font-family: "Roboto Condensed";
    color: #116ba6;
  }

  .menu_list {
    color: #116ba6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0;
  }

  .menu_list li {
    padding: 13px 0px;
    font-size: 14px;
    cursor: pointer;
  }
  .categorie {
    display: flex;
    justify-content: center;
    font-family: "Roboto Condensed";
    padding-top: 40px;
    flex-direction: column;
    align-items: start;
    color: #d2a45e;
    font-size: 16px;
  }
  .produs-main p {
    font-weight: 700;
    font-family: "Roboto Condensed";
    color: #116ba6;
    font-size: 16px;
  }
}
@media screen and (max-width: 340px) {
    .menu_list {
    color: #116ba6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0;
  }

  .menu_list li {
    padding: 13px 0px;
    font-size: 12px;
    cursor: pointer;
  }
  .meniu {
  padding-right: 25px;
  padding-left: 25px;
  width: 100%;
  background-color: #fbf8f1;
  margin-bottom: 2%;
  font-family: "Roboto Condensed";
}
  .wineContainer{
    display:flex; 
    justify-content:right;
    width:100px;
  }
.foodBarContainer{
  display:flex; 
  justify-content:left;
  width:100px;
}  
  .maseratiContainer {
    padding: 10px;
    display: flex;
    justify-content: center;
  }

  .maseratiImage {
    width: 80%;
    height: 100%;
  }

  .hiddenElement {
    min-width: 0px;
  }

  .hiddenElement1 {
    min-width: 21%;
  }

  .imageContainer[data-v-538ecc83] {
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
  }
  .tab_border {
    border-bottom: 3px solid #fbf8f1;
  }

  .tab_border_active {
    border-bottom: 3px solid #116ba6;
    padding-top: 20px;
  }
}

@media screen and (min-width: 500px) {
  .imageContainer2 {
    display: none;
  }

  .tab_border {
    border-bottom: 3px solid #fbf8f1;
  }

  .tab_border_active {
    border-bottom: 3px solid #116ba6;
    padding-top: 20px;
  }
}
@media screen and (max-width: 780px) and (max-height: 1050px) {
  .maseratiImage {
    width: 100%;
    height: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
}
</style>
