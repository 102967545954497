<template>
  <div class="ed-main-container">
    <div class="ed-second-container">
      <div class="ed-logo-container">
        <img
          src="../../assets/images/LOGO-Isoletta.png"
          height="80"
          width="260"
        />
      </div>
      <div class="ed-form">
        <form class="ed-theForm">
          <h3 class="edit-product-text">Titlu</h3>
          <textarea
            rows="3"
            class="ed-form-control"
            v-model="productData.titlu"
            :placeholder="[[productData.titlu]]"
          >
          </textarea>
          <h3 class="edit-product-text">Descriere</h3>
          <textarea
            rows="4"
            class="ed-form-control"
            v-model="productData.descriere"
            :placeholder="[[productData.descriere]]"
          >
          </textarea>
          <h3 class="edit-product-text">Pret</h3>
          <input
            class="ed-form-control"
            v-model="productData.pret"
            :placeholder="[[productData.pret]]"
          />
        <div class="submit_button">
          <button @click.prevent="addDataToFirestore" id="close" class="btn btn-primary" style="width: 100%; margin-top:20px">
            Salveaza Modificarile
          </button>
        </div>
        <div class="closeBtnContainer">
          <button @click.prevent = "backToProducts()" class="closeWindowBtn">Go Back</button>
        </div>
      </form>
      </div>
      
    </div>
  </div>
</template>

<script>
import { doc, getDoc, getFirestore, updateDoc} from "@firebase/firestore";
import router from "../../router/index";

export default {
  name: "edit_product",

  data() {
    return {
      productData: {},
      menu: [],
    };
  },

  methods: {
    //add data updatetd to firestore
    async addDataToFirestore() {
      const indexOfItem = this.menu.findIndex(item => item.id === this.productData.id);
      const item = {
        index: this.productData.index,
        subcategorieObj: {
          index: this.productData.subcategorieObj.index,
          subcategorie: this.productData.subcategorie.trim().replace('  ', ' ')
        },
        titlu: this.productData.titlu,
        descriere: this.productData.descriere,
        pret: this.productData.pret,
        categorie: this.productData.categorie,
        id: this.productData.id,
        subcategorie: this.productData.subcategorie,
      };
      console.log("item", item);
      console.log("prod", this.productData);
      
      const db = getFirestore();
      const restaurant = await getDoc(doc(db, "data", "isoletta"));
      this.menu = restaurant.data().menu;
      
      this.menu[indexOfItem]=item;
      await updateDoc(doc(db, "data", "isoletta"), {
        menu: this.menu,
      });
      router.push({ path: "/products" });
    },
    backToProducts(){
      router.push({ path: "/products" });
    },
  },

  async mounted() {
    const id = this.$route.params.id;
    const db = getFirestore();
    const restaurant = await getDoc(doc(db, "data", "isoletta"));
    this.menu = restaurant.data().menu;
     for (let document of this.menu) {
       if(document.id === id){
        this.productData = document;
       }
     }
  },
};
</script>

<style>
.ed-main-container {
  background: #116ba6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #116ba6,
    #d2a45e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #116ba6,
    #d2a45e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100vh;
}
.ed-second-container {
  margin: auto;
  min-width: 37%;
  background-color: white;
  padding: 40px 0px 40px 0px;
  border: 1px solid transparent;
  border-radius: 10px;
  position: absolute;
  top: 12%;
  left: 30%;
}

.ed-logo-container {
  display: flex;
  justify-content: center;
}

.edit-product-text {
  text-align: center;
  font-family: "Helvetica";
  font-size: 0.9em;
  font-weight: 400;
  color: #222;
  padding-top: 10px;
}

.submit_button{
  width: 100%;
  display: flex;
  justify-content: center;
}

.ed-form-group {
  width: 300px;
  padding: 10px;
}

.submit-button {
  display: flex;
  justify-content: center;
}

.ed-form {
  display: flex;
  justify-content: center;
}

.ed-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #e9ecef;
  background-clip: padding-box;
  border: 1px solid #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-colo 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (max-width: 700px), screen and (max-height: 820) {
  .ed-second-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    left: 15%;
    right: 15%;
    top: 19%;
  }
  .ed-main-container {
    background: #116BA6;
    background: linear-gradient(to top, #116BA6, #D2A45E);
    height: 110vh;
  }
}

@media screen and (max-width: 500px), screen and (max-height: 700) {
  .ed-second-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    left: 5%;
    right: 5%;
    top: 14%;
  }
  .ed-main-container {
    background: #116BA6;
    background: linear-gradient(to top, #116BA6, #D2A45E);
    height: 110vh;
  }
}

@media screen and (max-width: 360px), screen and (max-height: 550) {
  .ed-second-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    left: 1%;
    right: 1%;
    top:5%;
  }
  .ed-main-container {
    background: #116BA6;
    background: linear-gradient(to top, #116BA6, #D2A45E);
    height: 114vh;
  }
}
</style>
