<template>
  <div class="main-container">
    <div class="second-container">
      <div class="login-title-container">
        <img src="../assets/images/LOGO-Isoletta.png" height="80" width="260" />
      </div>
      <div class="form">
        <form class="secondForm">
          <div class="form-group" >
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Email"
            />
          </div>
          <div class="form-group">
            <input
              v-model="password"
              type="password"
              class="form-control"
              placeholder="Password"
            />
          </div>
          <div class="submit-button">
            <button
              @click.prevent="login()"
              class="btn btn-primary"
              style="width: 280px; margin-top:20px"
            >
              Login
            </button>
          </div>
          <!-- <div class="login-options">
            <div class="signed-in-options">
              <input type="checkbox" id="signedIn" name="signedIn" />
              <label class="signed-in" for="signedIn">Keep me signed in</label
              ><br />
            </div>
            <div>
              <p>Forgot Password</p>
            </div>
          </div> -->
          <!-- <div style="display: flex; padding:10px; justify-content: center;">
            <p class="new-user-options">New user?</p>
            <p class="new-user-options bolded">Sign Up</p>
          </div> -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },

  methods: {
    login() {
      const auth = getAuth();
      setPersistence(auth, browserSessionPersistence).then(async () => {
        return signInWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            this.$router.replace({
              name: "Products",
            });
          })
          .catch((error) => {
            const errorCode = error.code;
            console.log(errorCode);
            const errorMessage = error.message;
            console.log(errorMessage);
          });
      });
    },
  },
};
</script>

<style>
.loginBackground {
  height: 100vh;
  width: 100vh;
}

.main-container {
  background: #bdc3c7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #116ba6,
    #d2a45e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #116ba6,
    #d2a45e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100vh;
}

.login-title-container {
  display: flex;
  justify-content: center;
}

.login-title {
  text-align: center;
  font-family: "Helvetica";
  font-size: 2.2em;
  font-weight: 500;
  margin-bottom: 10px;
}

.login-text {
  text-align: center;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: 200;
  color: #222;
  padding-top: 10px;
}

.form {
  display: flex;
  justify-content: center;
}

.secondForm {
  display: flex;
  flex-direction: column;
  padding-top:15px;
}

.form-group {
  width: 300px;
  padding: 10px;
}

.form-group input{
  width:280px !important;
  height:40px !important;
  text-align: center;
}

.form-input {
  width: 200px;
}

.form-control {
  border: 1px solid #f9fafb !important;
  background: #e9ecef;
}

.submit-button {
  padding-bottom: 10px;
  width: 100%;
}

.login-options {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.login-options p {
  font-size: 10px;
  justify-content: space-between;
}

.signed-in-options {
  display: flex;
  color: #222222;
}

.signed-in {
  font-size: 10px;
  margin-left: 5px;
  color: #222222;
}

.new-user-options {
  text-align: center;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: 300;
  color: black;
}

.bolded {
  font-weight: 600;
  margin-left: 5px;
}

.second-container {
  margin: auto;
  min-width: 37%;
  background-color: white;
  padding: 40px 0px 40px 0px;
  border: 1px solid transparent;
  border-radius: 10px;
  position: absolute;
  top: 20%;
  left: 30%;
}

@media screen and (max-width: 700px) {
  .second-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    left: 20%;
    right: 20%;
  }
}

@media screen and (max-width: 500px) {
  .second-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    left: 10%;
    right: 10%;
  }
}

@media screen and (max-width: 360px) {
  .second-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    left: 3%;
    right: 3%;
  }
}
</style>
