import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import index from "./store";

//import firebase from "firebase";
import { initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCA9W-gGLXG16kRaY7SLhNT8GlU6XIu2Tg",
  authDomain: "isolettaweb.firebaseapp.com",
  projectId: "isolettaweb",
  storageBucket: "isolettaweb.appspot.com",
  messagingSenderId: "603656304202",
  appId: "1:603656304202:web:7beadd3ff433c24d9358b3",
  measurementId: "G-RKFWS3LS2F",
};

export const app = initializeApp(firebaseConfig);

//firebase.initializeApp(firebaseConfig);

let appReload;

getAuth().onAuthStateChanged((user) => {
  console.log(user);
  if (!appReload)
    appReload = createApp(App)
      .use(index)
      .use(router)
      .mount("#app");
});
