<template>
  <div class="section">
    <header class="header">
      <button @click.prevent="homeProduct" class="btn_logout">Sign Out</button>
    </header>

    <div class="menu">
      <ul class="menu_list">
        <div class="leftContainer">
          <li
            class="tab-item_food tab_border tab_border_active"
            @click="removeBorder"
            v-on:click="displayCategory('food')"
          >
            FOOD
          </li>
          <div class = "hiddenElement1"></div>
          <li
            class="tab-item_bar tab_border"
            @click="removeBorder"
            v-on:click="displayCategory('bar')"
          >
            BAR
          </li>
        </div>
        <div class = "imageContainer">
          <img class ="image" src="..\..\assets\images\logo.png" />
        </div>
        <div class = "rightContainer">
          <li
            class="tab-item_wine tab_border"
            @click="removeBorder"
            v-on:click="displayCategory('wine')"
          >
            VINI & CHAMPAGNE
          </li>
        </div>
      </ul>
    </div>

    <div class="main-div">
      <div v-for="(values, key) in produseSubcategorizate" :key="key" class="subcat-master-container" :data-index="this.categorii2[this.categorieSelectata].find(e => e.subcategorie == key.replace('  ', ' ')).index">
        <div style = "display:flex">
          <h2 class="categorie">{{ key }}</h2>

          <div v-if="this.categorii2[this.categorieSelectata].find(e => e.subcategorie == key.replace('  ', ' ')).index != 0" style = "min-width:50px; margin-left: 40px;">
            <img @click="decreaseIndex(this.categorii2[this.categorieSelectata].find(e => e.subcategorie == key.replace('  ', ' ')).index, key)" class="arrow-up" src="..\..\assets\images\chevron-up.svg" />
          </div>

          <div v-if="this.categorii2[this.categorieSelectata].find(e => e.subcategorie == key.replace('  ', ' ')).index != ( this.categorii2[this.categorieSelectata].length - 1 )" style = "min-width:50px;">
            <img @click="increaseIndex(this.categorii2[this.categorieSelectata].find(e => e.subcategorie == key.replace('  ', ' ')).index, key)" class="arrow-down" src="..\..\assets\images\chevron-up.svg" />
          </div>

        </div>
        <div v-for="produs of values" :key="produs" class="container-produs" :data-id="produs.id" :data-index="produs.index">
          <div v-if="produs.categorie === 'bar'">
            <div class="produs">
              <div class="titluPret">
                <p>{{ produs.titlu }}</p>
                <p>{{ produs.pret }} lei</p>
              </div>
              <!--  start buttons -->
              <div class="buttons">
                  <button @click="openPopUp(produs.id)" class="btn" style = "background-color: #dab278;">
                  Sterge
                </button>
                <!-- router-link -->
                <router-link
                  v-bind:to="{
                    name: 'EditProduct',
                    params: {
                      id: produs.id.trim(),
                    },
                  }"
                >
                  <button id="editeaza" class="btn">Editeaza</button>
                </router-link>
              </div>
            </div>
          </div>
          
          <div v-else class="produs">
            <div class="produs-main">
              <p>{{ produs.titlu }}</p>
              <p v-if="produs.descriere.trim() !== ''" class="produs-descriere">
                {{ produs.descriere }}
              </p>
              <p>{{ produs.pret }} lei</p>
            </div>
            <!--  start buttons -->
            <div class="buttons">
                <button @click="openPopUp(produs.id)" class="btn"  style = "background-color: #dab278;">
                Sterge
              </button>
              <!-- router-link -->
              <router-link
                v-bind:to="{
                  name: 'EditProduct',
                  params: {
                    id: produs.id.trim(),
                  },
                }"
              >
                <button id="editeaza" class="btn">Editeaza</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- The Modal -->
      <div id="myModal" class="modal">
        <div class="modal-content">
          <span @click="closePopUp()" class="close">&times;</span>
          <p class = "modal-text">Sigur vreti sa stergeti acest produs?</p>
          <div class = "popupContainer">
            <button @click="deleteById()" id="delete" class = "btnDelete">Da</button>
            <button @click="closePopUp()" class = "btnDelete">Nu</button>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-button">
      <button
        @click.prevent="addProduct"
        class="plus-button plus-button--large"
      ></button>
    </div>
  </div>
</template>

<script>
import {
  getFirestore,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import router from "../../router/index";
import{getAuth}from "firebase/auth";
// import { Sortable } from '@shopify/draggable';
import Sortable from 'sortablejs';

export default {
  name: "Home",
  components: {
  },
  data: () => {
    return {
      isVisibility: false,
      categorii: [],
      categorieSelectata: "food",
      produseCategorizate: {},
      produseSubcategorizate: {},
      menu: [],
      selectedProductId: null,
      categorii2: {
        food: [],
        wine: [],
        bar: []
      }
    };
  },

  methods: {
    async increaseIndex(index, subcategory){
      const menu = this.menu;
      const subcat = subcategory.replace('  ', ' ');

      for(let produs of menu){
        if(produs.categorie == this.categorieSelectata){
          if(produs.subcategorieObj.subcategorie == subcat){
            produs.subcategorieObj.index++;
          }
          else if(produs.subcategorieObj.index == index + 1){
            produs.subcategorieObj.index--;
          }
        }
      }

      const db = getFirestore();

      await updateDoc(doc(db, "data", "isoletta"), {
        menu: menu,
      })
    },

    async decreaseIndex(index, subcategory){
      const menu = this.menu;
      const subcat = subcategory.replace('  ', ' ');

      for(let produs of menu){
        if(produs.categorie == this.categorieSelectata){
          if(produs.subcategorieObj.subcategorie == subcat){
            produs.subcategorieObj.index--;
          }
          else if(produs.subcategorieObj.index == index - 1){
            produs.subcategorieObj.index++;
          }
        }
      }

      const db = getFirestore();

      await updateDoc(doc(db, "data", "isoletta"), {
        menu: menu,
      })
    },

    openPopUp(productId) {
      this.selectedProductId = productId;
      var modal = document.getElementById("myModal");
      var span = document.getElementsByClassName("close")[0];
      modal.style.display = "block";
      span.onclick = function() {
        modal.style.display = "none";
      }
    },
    closePopUp() {
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
    },

    removeBorder(event) {
      const food = document.querySelector(".tab-item_food");
      const wine = document.querySelector(".tab-item_wine");
      const bar = document.querySelector(".tab-item_bar");

      bar.classList.remove("tab_border_active");
      food.classList.remove("tab_border_active");
      wine.classList.remove("tab_border_active");

      if (event.target.innerText.includes("BAR")) {
        bar.classList.add("tab_border_active");
      } else if (event.target.innerText.includes("FOOD")) {
        food.classList.add("tab_border_active");
      } else {
        wine.classList.add("tab_border_active");
      }
    },

    addProduct() {
      router.push({ path: "/add_product" });
    },

    homeProduct() {
      return getAuth()
        .signOut()
        .then(() => {
          console.log("Delogat");
          this.$router.replace({
            name: "Menu",
          });
        });
    },

    //delete product
    async deleteById() {
      this.menu = this.menu.filter((item) => item.id !== this.selectedProductId);
      const db = getFirestore();
      await updateDoc(doc(db, "data", "isoletta"), {
        menu: this.menu,
      });
      this.$router.go();
    },

    //display data
    displayCategory(categorie) {
      this.categorieSelectata = categorie;
      this.subcategorizeazaProduse(
        this.produseCategorizate[this.categorieSelectata]
      );
    },

    categorizeazaProduse(listaDocumente) {
      for (let document of listaDocumente) {
        if (!this.categorii.includes(document.categorie)) {
          this.categorii.push(document.categorie);
        }

        if (this.produseCategorizate[document.categorie] == null) {
          this.produseCategorizate[document.categorie] = [document];
        } else {
          this.produseCategorizate[document.categorie] = [
            ...this.produseCategorizate[document.categorie],
            document,
          ];
        }
      }
    },

    subcategorizeazaProduse(listaProduseCategorizate) {
      this.produseSubcategorizate = {};
      for (let produs of listaProduseCategorizate) {
        const subcategorie = produs.subcategorie;

        if (this.produseSubcategorizate[subcategorie] == null) {
          this.produseSubcategorizate[subcategorie] = [produs];
        } else {
          this.produseSubcategorizate[subcategorie] = [
            ...this.produseSubcategorizate[subcategorie],
            produs,
          ];
        }
      }
    },
  },

  async mounted() {
    const db = getFirestore();
    const restaurant = await getDoc(doc(db, "data", "isoletta"));
    this.menu = restaurant.data().menu;

    this.categorizeazaProduse(this.menu);

    this.subcategorizeazaProduse(
      this.produseCategorizate[this.categorieSelectata]
    );    
  },

  beforeUpdate(){
    let tempArr = [];
    for(let produs of this.menu){
      if(produs.categorie == this.categorieSelectata){
        if(!tempArr.find(element => element.subcategorie == produs.subcategorieObj.subcategorie)){
          tempArr.push(produs.subcategorieObj);
        }
      }
    }
    this.categorii2[this.categorieSelectata] = tempArr.sort((a,b) => a.index - b.index);


    let tempArr2 = [];
    this.categorii2[this.categorieSelectata].forEach(subcatObj => {
      for(let subcategorie in this.produseSubcategorizate){
        if( subcategorie.replace( '  ', ' ' ) == subcatObj.subcategorie.replace( '  ', ' ' ) ){
          tempArr2[subcategorie] = this.produseSubcategorizate[subcategorie];
        }
      }
    });
  },

  async updated(){
    let containers = document.querySelectorAll('.subcat-master-container');
    let arr = Array.from(containers);
    let sorted = arr.sort((a, b) => a.getAttribute('data-index') - b.getAttribute('data-index'));
    sorted.forEach(e => {
      document.querySelector('.main-div').appendChild(e);
    })

    const db = getFirestore();
    let newDocs = [...this.menu];

    containers.forEach(parent => {
      let produse = parent.querySelectorAll('.container-produs');
      let arr = Array.from(produse);

      let sortedProduse = arr.sort((a, b) => a.getAttribute('data-index') - b.getAttribute('data-index'));
      sortedProduse.forEach(e => {
        parent.appendChild(e);
      })

      Sortable.create(parent, {
        animation: 150,
        draggable: '.container-produs',
        // handle: '.glyphicon-move',
        onEnd(e){
          let newIndex = e.newIndex - 1;
          let oldIndex = e.oldIndex - 1;
          console.log(newIndex, oldIndex);

          let produsVechi = e.from.querySelectorAll('.container-produs')[newIndex];
          let produsNou = e.from.querySelectorAll('.container-produs')[oldIndex];

          let tempIndexVechi = produsVechi.getAttribute('data-index');
          let tempIndexNou = produsNou.getAttribute('data-index');

          produsVechi.setAttribute('data-index', tempIndexNou);
          produsNou.setAttribute('data-index', tempIndexVechi);
          for(let doc of newDocs){
            if(doc.id == produsVechi.getAttribute('data-id')){
              doc["index"] = parseInt(produsVechi.getAttribute('data-index'));
            }
            else if(doc.id == produsNou.getAttribute('data-id')){
              doc["index"] = parseInt(produsNou.getAttribute('data-index'));
            }
          }

          updateDoc(doc(db, "data", "isoletta"), {
            menu: newDocs,
          });
        }
      });
    })
  }
};
</script>

<style scoped>
.arrow-up{
  padding-top:40px;
  margin-left: 10px;
  cursor: pointer;
}

.arrow-down{
  padding-bottom:40px;
  margin-left: 25px;
  cursor: pointer;
  transform: rotate(180deg);
}

.produs-descriere {
  margin-bottom:0.2rem !important;
  margin-top: -0.8rem !important;
  padding-left: 0px;
  font-weight: 200 !important;
  font-family: "Roboto Condensed";
  color: #116ba6;
}

.rightContainer{
  display:flex; 
  justify-content:center;
  width:30%;
}

.leftContainer{
  display:flex; 
  justify-content:space-between;
  width:30%;
}

.titluPret{
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}
.popupContainer {
  display: flex;
  justify-content: center;
}
/* The Modal (background) */
.modal {
  display: none; 
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 20px;
  border: 1px solid #888;
  width: 80%; 
}
.modal-text{
  display: flex;
  justify-content: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: right;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.btnDelete {
  outline: none;
  display: block;
  border: 0;
  font-size: 14px;
  line-height: 1;
  padding: 12px 25px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s linear;
  font-family: 'Roboto Condensed';
  margin: 10px;
}
a {
  text-decoration: none;
}

.section {
  background-color: white;
  font-family: 'Roboto Condensed';
  width: 100%;
}
.menu {
  padding-left:90px;
  padding-right:90px;
  display:flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fbf8f1;
  margin-bottom: 2%;
  font-family: 'Roboto Condensed';
}

.menu_list {
  padding-left:0;
  padding-right:0;
  color: #116BA6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-width: 100%;
}
.menu_list li {
  font-size: 22px;
  cursor: pointer;
}

.main-div {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 90px;
  padding-right: 90px;
}

.categorie {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-family: 'Roboto Condensed';
  padding-top: 40px;
  flex-direction: column;
  color: #d2a45e;
}

.produs-main {
  display: flex;
  justify-content: space-between;
  display: inline-block;
  vertical-align: top;
  color: #116ba6;
}

.produs-main p {
  font-weight: 700;
  font-family: 'Roboto Condensed';
}

.produs-descriere {
  font-weight: 200 !important;
  font-family: 'Roboto Condensed';
  color: #116ba6;
}

.produs {
  border-bottom: 4px solid #999;
  padding-bottom: 10px;
  border-bottom: 2px solid #f1e9d5;
  border-bottom-style: dotted;
  background-color: white;
}

.buttons {
  display: flex;
}
.buttons button {
  background-color: #77bbe9;
}

.tab-item_wine {
  list-style-type: none;
}
.btn {
  outline: none;
  display: block;
  border: 0;
  font-size: 14px;
  line-height: 1;
  padding: 12px 25px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s linear;
  font-family: 'Roboto Condensed';
  margin: 10px;
}

.btn:hover {
  background-color: #005c99;
}

.modal-card {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.modal-container {
  background-color: #233b4b;
  border-radius: 20px;
  box-align: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 30px 50px;
  width: 500px;
  max-width: 500px;
  height: 250px;
  max-height: 250px;
  text-align: center;
}
.modal-title h3 {
  margin-bottom: 80px;
  color: white;
}

.modal-buttons {
  display: flex;
  justify-content: center;
}

.fixed-button {
  font-size: 60px;
  width: 90px;
  position: fixed;
  top: 75%;
  left: 95%;
}

.plus-button {
  border: 2px solid #005c99;
  background-color: #fff;
  font-size: 16px;
  height: 2.5em;
  width: 2.5em;
  border-radius: 999px;
  position: relative;
}

.plus-button::after {
  content: "";
  display: block;
  background-color: #005c99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plus-button::before {
  content: "";
  display: block;
  background-color: #005c99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plus-button:before {
  height: 1em;
  width: 0.2em;
}

.plus-button:after {
  height: 0.2em;
  width: 1em;
}

.plus-button--small {
  font-size: 12px;
}

.plus-button--large {
  font-size: 22px;
}

.header {
  position: relative;
  box-sizing: border-box;
}

.btn_logout {
  outline: none;
  display: block;
  color: white;
  background-color: #4486b3;
  border: 0;
  font-size: 14px;
  line-height: 1;
  padding: 12px 25px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s linear;
  font-family: 'Roboto Condensed';
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
}

.btn_logout:hover {
  background-color: #005c99;
}

@media screen and (max-width: 800px) {
.menu {
  padding-left:20px;
  padding-right:20px;
  display:flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fbf8f1;
  margin-bottom: 2%;
  font-family: 'Roboto Condensed';
}

.rightContainer{
  display:flex; 
  justify-content:right;
  width:150px;
}

.leftContainer{
  display:flex; 
  justify-content:left;
  width:150px;
}

.menu_list {
  color: #116BA6;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-height: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-width: 100%;
}

.menu_list li {
  padding: 14px 5px;
  font-size: 12px;
  cursor: pointer;
}

.main-div {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.btn_logout {
  top: 48px ;
  left:10px;
}
.fixed-button {
  left: 80%;
  top: 85%;
}
}

@media screen and (max-width: 325px) {
.rightContainer{
  display:flex; 
  justify-content:right;
  width:100px;
}

.leftContainer{
  display:flex; 
  justify-content:left;
  width:100px;
}
.btn_logout {
  top: 48px;
}
.fixed-button {
  left: 80%;
  top: 85%;
}
.menu_list li {
  padding: 14px 5px;
  font-size: 9px;
  cursor: pointer;
}
}
@media screen and (min-width: 500px){ 
.tab_border{
  border-bottom: 3px solid #fbf8f1;
}

.tab_border_active {
    border-bottom: 3px solid #116BA6;
}
}
</style>
