import { createRouter, createWebHistory } from "vue-router";
import Menu from "../pages/Menu.vue";
import Login from "../pages/Login.vue";
import Products from "../pages/Dashboard/Products.vue";
import EditProduct from "../pages/Dashboard/EditProduct.vue";
import AddProduct from "../pages/Dashboard/AddProduct.vue";
import { getAuth } from "firebase/auth";

const routes = [
  {
    path: "/",
    name: "Menu",
    component: Menu,
    props:true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit_product/:id",
    name: "EditProduct",
    component: EditProduct,
  },
  {
    path: "/add_product",
    name: "AddProduct",
    component: AddProduct,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAutentificate = getAuth().currentUser;

  if (requiresAuth && !isAutentificate) {
    next("/login");
  } else {
    next();
  }
});

export default router;
