<template>
  <footer class="footer">
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
    <div class="column">
      <div class="left-column">
        <p class="left-column-3">Sos. Nordului 7-9 (near the BTT tennis courts)</p>
        <div class="left-column-1">
          <a style="color:#116BA6; text-decoration: none;" href="tel:+407308220852">+4(0730) 82 20 85,</a>
          <div style="min-width:10px;"></div>
          <a style="color:#116BA6; text-decoration: none; " href="tel:+40212322188"> +4(021) 232 21 88</a>
        </div >
      </div>

      <div class="right-column">
          <div class = "socialIconsContainer">
          <a href="https://www.facebook.com/isoletta.ristorante"><img class = "facebookImage" src="..\assets\images\facebook.svg" /></a>
          </div>
          <div class = "socialIconsContainer">
          <a href="https://www.instagram.com/isolettaristorante/"><img class = "instaImage" src="..\assets\images\instagram.svg" /></a>
          </div>
          <!-- <div class = "loginBtnImage" @click.prevent = "goToLoginPage()"><img src="..\assets\images\sign-in.svg" style = "width:27px"/></div> -->
      </div>
    </div>
  </footer>
</template>

<script>
import router from "../router/index.js";
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods:{
    goToLoginPage(){
      router.push({ path: "/login" });
    }
  }
};
</script>

<style>
.loginBtnImage{
  background: #116BA6;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
}


.facebookImage{
  width: 30px;
  height: 22px;
}

.instaImage{
  width: 26px;
  height: 27px;
}

.left-column-1{
  display:flex;
  font-weight: bold;
}
.left-column-3{
  color:#116BA6;
}

.right-column-1{
  display:flex;
  justify-content: space-between;
}

.socialIconsContainer{
  background: #116BA6;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  background-color: #fbf8f1;
  color: #fff;
  left: 0;
  bottom: 0;
  width: 100%;
}

.column {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top:10px;
  padding-bottom: 10px;
}

.left-column {
  text-align: center;
  padding-left:10%;
}

.right-column {
  display:flex;
  justify-content: space-around;
  width: 25%;
  padding-right: 10%;
}

.right-column-2 {
  padding-top: 2vh;
}

.right-column-3 {
  cursor: pointer;
  margin-top:23px;
}

.footer-text {
  display: flex;
}

.footer-text a {
  margin: 0 auto;
  color: #000;
}

.footer-text a span:hover {
  color: #5dc408;
}

.footer a {
  color: #fff;
  padding-bottom: 0;
}

a:hover {
  color: #5dc408 !important;
  text-decoration: none !important;
}

.social-icons{
  padding-top:1vh;
}

.social-icons i {
  cursor: pointer;
  color: #fff;
}

.social-icons i:hover {
  color: #5dc408;
}

.fa {
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.7;
}

@media (max-width: 700px) and (min-height: 330px){
.right-column{  
 display:flex;
  justify-content: space-around;
  width: 25%;
  padding-right: 0;
}  
.column{
  min-width:100%;
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: center;
}

.left-column {
  padding-left: 25px;
  padding-top:10px;
  text-align: left;
  font-size:11px;
}

.right-column{
  display: flex;
  justify-content: space-evenly;
  width:40%;
  margin-top: 10px;
}
  
.loginBtnImage {
  background: #116BA6;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
}
</style>
